<template>
  <section class="content">
    <div class="card card-info">
      <div class="card-header">
      <div class="box-tools card-tools toolbar">
      <div class="btn-group">
        <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fas fa-plus"></i> Buat Pesanan
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <router-link to="/order/create/single" class="dropdown-item">
          <button type="button" class="btn btn-sm dropdown-item" > 
                <i class="fas fa-edit"></i> Input Manual
          </button>
          </router-link>
          <router-link to="/order/create" class="dropdown-item">
          <button type="button" class="btn btn-sm dropdown-item" > 
                <i class="fas fa-edit"></i> Input Manual Batch
          </button>
          </router-link>
          <router-link to="/order/import" class="dropdown-item">
          <button type="button" class="btn btn-sm dropdown-item" > 
                <i class="fas fa-upload"></i> Import
          </button>
          </router-link>
        </div>
      </div>
      </div>
      </div>

      <div class="card-body">
        <div class="table-responsive table-ordergroup">
          <table class="table table-hover w-100" ref="tableordergroup">
            <thead>
              <tr>
                <th>BATCH ID</th>
                <th>WAKTU</th>
                <th>PENGIRIM</th>
                <th>KURIR</th>
                <th>ALAMAT</th>
                <th>TOTAL</th>
                <th>ERROR</th>
                <th>STATUS</th>
                <!-- <th>TINDAKAN</th> -->
              </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formPrint"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form">
            <div class="modal-header">
              <h5 class="modal-title" id="title_saldo">Cetak Resi</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-6">
                  <div class="info-box2 mb-3 clearfix">
                    <span
                      class="info-box-icon float-left"
                      style="width: 50px; height: 50px; font-size: 1.875rem"
                      ><i class="fas fa-file-pdf fa-w-12"></i
                    ></span>

                    <div class="info-box-content">
                      <div class="info-box-text">File PDF</div>
                      <div class="info-box-number">
                        {{ totalItems }} Pesanan {{ totalPages }} Halaman
                      </div>
                    </div>
                    <!-- /.info-box-content -->
                  </div>
                </div>
                <div class="col-6 text-right">
                  <button
                    ref="elDownload"
                    type="button"
                    @click="printLabel"
                    :disabled="downloading"
                    class="btn btn-primary"
                  >
                    {{ printBtnMessage }}
                  </button>
                </div>
              </div>
            </div>
            <div class="modal-footer pl-0">
              <div class="row" style="width: 100%; align-items: center">
                <div class="col-6">Template</div>
                <div class="col-6 text-right">
                  <button
                    v-if="false"
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Tutup
                  </button>
                  <select
                    class="form-control"
                    v-model="templateOpt"
                    @change="templateChange($event)"
                  >
                    <option value="2">2 Label per Halaman </option>
                    <option value="5">10cm x 8.5cm</option>
                    <option value="6">10cm x 10cm</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { authFetch, createTable, auth } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import moment from "moment";
export default {
  nama: "List Order Group",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      err: "",
      totalItems: 0,
      totalPages: 0,
      no_resi: "",
      terkirim: "",
      isOpen: false,
      printBtnMessage: "",
      urlDownload: "",
      downloading: false,
      templateOpt: 5,
      groupId: 0,
      url: window.$apiUrl,
      authEmail:"",
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
    if (localStorage.defaultTpl) this.templateOpt = localStorage.defaultTpl;
    // $("#my-card-widget").refreshBox("load");
    // setInterval({
    // }, 300);
  },
  methods: {
    handleClick(e) {
      console.log(e.target);
      if (e.target.matches(".link-order")) {
				let route = this.$router.resolve({
					path: "order/count/" + e.target.dataset.id
				});
				window.open(route.href,"_blank");
				return false;
			} else if (e.target.matches("a")) {
        if(e.target.innerHTML=='0')
        {
          return false;
        }
        var id = atob(e.target.dataset.id);
        var filter = e.target.dataset.filter;
        var parameter = {gid: e.target.dataset.id};
        parameter[filter] = 1;
        parameter['dt1'] = moment(e.target.dataset.dt, "DD-MM-YYYY HH:ii").format("YYYY-MM-DD");
        parameter['dt2'] = moment(e.target.dataset.dt, "DD-MM-YYYY HH:ii").format("YYYY-MM-DD");
        this.$router.push({ name: "Riwayat", params: {filter: parameter} });
        return false;
      }
      if (e.target.matches("button")) {
        this.authEmail = auth.user().email;
        if (e.target.dataset.action == "edit") {
          this.$router.push({ path: "/order/edit/" + atob(e.target.dataset.id) });
        }
        if (e.target.dataset.action == "print") 
        {
          const r = this.$refs;
          this.totalPages = 1;
          this.totalItems = e.target.dataset.total;
          var groupId = atob(e.target.dataset.id);
          if (this.groupId != groupId) {
            this.groupId = groupId;
            this.isOpen = false;
          }
          $(r.formPrint).modal("show");
        } else if (e.target.dataset.action == "remove") {
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/order/group/" + atob(e.target.dataset.id), {
                method: "DELETE",
                body: "id=" + atob(e.target.dataset.id),
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.tbl.api().ajax.reload();
                });
            }
          });
        }
        //this.$router.push({ path: '/permit/' + atob(e.target.dataset.id) })
        console.log(e.target);
        return false;
      }
    },
    downloadLabel() {
      this.printBtnMessage = "Proses...";
      localStorage.defaultTpl = this.templateOpt;
      this.downloading = true;
      if (this.templateOpt < 5) {
        var nPage = parseInt(this.totalItems / this.templateOpt);
        this.totalPages =
          this.totalItems % this.templateOpt == 0 ? nPage : nPage + 1;
      } else {
        this.totalPages = this.totalItems;
      }
      authFetch(
        "/report/cetakresi?id=" +
          this.groupId +
          "&template=" +
          this.templateOpt + 
          "&mail=" +
          this.authEmail,
        {
          method: "GET",
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          setTimeout(() => {
            this.downloading = false;
          }, 1000);
          var url = window.URL.createObjectURL(blob);
          this.urlDownload = url;
          this.printBtnMessage = "Download";
        });
    },
    printLabel() {
      var a = document.createElement("a");
      a.href = this.urlDownload;
      a.download = "Data_Label-" + moment().format("YYMMDDHHmmss") + ".pdf";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    },
    templateChange(e) {
      this.downloadLabel();
    },
  },
  mounted() {
		this.$gtag.event(`page_view`, {
			'event_name': 'Daftar Pesanan'
		});
    const e = this.$refs;
    const self = this;
    this.tbl = createTable(e.tableordergroup, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/order/arsip_group",
      frame: false,
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      serverSide: true,
      selectedRowClass: "",
      lengthChange: true,
      filter: true,
			scrollX: true,
      filterBy: [0, 2, 3],
      "order": [[ 0, 'desc' ]],
      columns: [
        {
          data: "code",
					render: function (data, type, row, meta) {
						return (`<span class="link link-order text-info" style="cursor:pointer;" data-id="${row.id}">${data}</span>`);
					},
        },
        { data: "pickup_time" },
        { data: "pic_name" },
        { data: "nama" },
        { data: "kota", visible: false },
        { data: "total", class: 'text-center',
          render: function (data, type, row, meta) {
            return '<a class="link" href="#" data-id="'+row.id+'" data-filter="all" onclick="return false;" data-dt="' + row.pickup_time + '">' + data + '</a>';
          }
        },
        {
          data: "errors", class: 'text-center',
          render: function (data, type, row, meta) {
            return '<a class="link" href="#" data-id="'+row.id+'" data-filter="err" onclick="return false;" data-dt="' + row.pickup_time + '">' + data + '</a>';
          }
        },
        { data: "status" },
        // { data: "status", sortable:false },
      ],
      rowCallback: function (row, data) {
        let dropdown1 =
          `<div class="btn-group">
  <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  </button>
  <div class="dropdown-menu dropdown-menu-right">
  <button type="button" class="btn btn-sm btn-warning dropdown-item" data-action="edit" data-id="` +
          btoa(data.id) +
          `"> Lanjutkan </button>
  <button type="button" class="btn btn-sm btn-danger dropdown-item" data-action="remove" data-id="` +
          btoa(data.id) +
          `">Hapus</button>
  </div>
</div>`;

        let dropdown2 =
          `<div class="btn-group">
  <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  </button>
  <div class="dropdown-menu dropdown-menu-right">
  <button type="button" class="btn btn-sm btn-warning dropdown-item" data-action="edit" data-id="` +
          btoa(data.id) +
          `"> Lihat Pesanan </button>
  <button type="button" class="btn btn-sm btn-warning dropdown-item" data-action="print" data-total="` +
          data.total +
          `" data-id="` +
          btoa(data.id) +
          `"> Cetak Label </button>
  </div>
</div>`;
        if (data.job_status == 1){
          $("td:eq(6)", row).html(
              '<span class="badge badge-secondary">Menunggu resi</span>'
            );
        } else if (data.job_status == 2){
          $("td:eq(6)", row).html(
              '<span class="badge badge-secondary">Generate resi</span>'
            );
        } else {
          if (data.status == 0) {
            $("td:eq(6)", row).html(
              '<span class="badge badge-secondary">Draft</span>'
            );
          } else {
            $("td:eq(6)", row).html(
              '<span class="badge badge-success">Submit</span>'
            );
          }
        }

        // $("td:eq(5)", row).html("<h5>" + data.errors + "</h5>");
        // $("td:eq(6)", row).html("<h5>" + data.belum_rekon + "</h5>");
        // $("td:eq(7)", row).html("<h5></h5>");
        // $("td:eq(8)", row).html("<h5>" + data.terkirim + "</h5>");
        // $("td:eq(11)", row).html("<h5>" + data.rekon + "</h5>");

        // if (!data.errors) {
        //   $("td:eq(5)", row).html("<h5>" + 0 + "</h5>");
        // }
        // if (!data.terkirim) {
        //   $("td:eq(6)", row).html("<h5>" + 0 + "</h5>");
        // }

        // if (data.order_type == 0) {
        //   $("td:eq(10)", row).html("<h6>Pickup</h6>");
        // } else {
        //   $("td:eq(10)", row).html("<h6>Drop Off</h6>");
        // }
        
        if (data.status == 1) {
          $("td:eq(7)", row).html(dropdown2);
        } else {
          $("td:eq(7)", row).html(dropdown1);
        }
      },
      initComplete: function () {
        $('.loading-overlay').removeClass('show');
      },
    });
    $(e.formPrint).on("show.bs.modal", (e) => {
      if (!this.isOpen) {
        this.downloadLabel();
        this.isOpen = true;
      }
    });
  },
};
</script>
<style>
.table-ordergroup .dataTables_length{padding-left: 10px;}
.table-ordergroup .dataTables_scrollBody{min-height: 100px}
</style>